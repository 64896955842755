import React from "react"
import Agegate from "../components/Agegate/agegateNew"

const NotFoundPage = ({location}) => {


  return (
    <Agegate location={location}/>
  )
}

export default NotFoundPage